.header-container {
    background: #175442;
    color: #f1f1f1;
    display: flex;
    z-index: 9999;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 65px;
    z-index: 999px;
}

.header-item-container {
    margin: 0px 4px;
    cursor: pointer;
    padding: 4px;
}

.menu-icon {
    cursor: pointer;
}