.grow { 
  transition: all .2s ease-in-out; 
}

.grow:hover { 
  transform: scale(1.1); 
}

#card:hover > .card-description {
  height: 100%;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  background-color: rgba(10, 10, 10, 0.8);
}

#card:hover > .card-description > .short-desc {
  display: none;
}

#card:hover > .card-description > .long-desc {
  height: 200;
  display: block;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}

.card-description {
  -webkit-backdrop-filter: saturate(180%) blur(5px);
  backdrop-filter: saturate(180%) blur(5px);
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: 1rem;
  left: 0;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  height: 30%;
  backdrop-filter: blur(5px); 
}

.card-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #fff;
}

.short-desc {
  font-size: 1rem;
  font-weight: 400;
  color: #fff;
}

.long-desc {
  font-size: 1rem;
  font-weight: 400;
  color: #fff;
  display: none;
}

